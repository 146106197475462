import React, { lazy } from 'react';
const Index = lazy(() => import('../pages/Index'));
const Report = lazy(() => import('../pages/Report'));
const ReportStatement = lazy(() => import('../pages/ReportStatement'));
const SiteSelection = lazy(() => import('../pages/SiteSelection'));
const TodoList = lazy(() => import('../pages/TodoList'));
const Login = lazy(() => import('../pages/Authentication/Login'));
const RecoverPassword = lazy(() => import('../pages/Authentication/RecoverPassword'));
const PettyCashList = lazy(() => import('../pages/StockBook/PettyCash/List'));
const PettyCashListAccounts = lazy(() => import('../pages/StockBook/PettyCash/ListAccounts'));
const PettyCashView = lazy(() => import('../pages/StockBook/PettyCash/View'));
const PettyCashAdd = lazy(() => import('../pages/StockBook/PettyCash/Add'));
const PettyCashEdit = lazy(() => import('../pages/StockBook/PettyCash/Edit'));
const PettyCashImport = lazy(() => import('../pages/StockBook/PettyCash/Import'));
const PettyCashAdvance = lazy(() => import('../pages/StockBook/PettyCash/Advance'));
const PettyCashAdvanceAdd = lazy(() => import('../pages/StockBook/PettyCash/AdvanceAdd'));
const Company = lazy(() => import('../pages/Master/Company'));
const Customer = lazy(() => import('../pages/Master/Customer'));
const Site = lazy(() => import('../pages/Master/Site'));
const SiteAdd = lazy(() => import('../pages/Master/SiteAdd'));
const PettyCashHead = lazy(() => import('../pages/Master/PettyCashHead'));
const PettyCashSubHead = lazy(() => import('../pages/Master/PettyCashSubHead'));
const PettyCashItems = lazy(() => import('../pages/Master/PettyCashItems'));
const EmployeesAdd = lazy(() => import('../pages/User/EmployeesAdd'));
const EmployeesList = lazy(() => import('../pages/User/EmployeesList'));
const DesignationList = lazy(() => import('../pages/User/DesignationList'));
const DesignationAdd = lazy(() => import('../pages/User/DesignationAdd'));

const routes = [
    {
        path: '/',
        element: <Index />,
        layout: 'default',
    },
    {
        path: '/report-petty-cash',
        element: <Report />,
        layout: 'default',
    },
    {
        path: '/petty-cash-statement',
        element: <ReportStatement />,
        layout: 'default',
    },
    {
        path: '/site-selection',
        element: <SiteSelection />,
        layout: 'header',
    },
    {
        path: '/todolist',
        element: <TodoList />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/list',
        element: <PettyCashList />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/list-accounts',
        element: <PettyCashListAccounts />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/add',
        element: <PettyCashAdd />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/edit',
        element: <PettyCashAdd />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/view',
        element: <PettyCashAdd />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/advance',
        element: <PettyCashAdvance />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/advance-add',
        element: <PettyCashAdvanceAdd />,
        layout: 'default',
    },
    {
        path: '/stock-book/petty-cash/advance-edit',
        element: <PettyCashAdvanceAdd />,
        layout: 'default',
    },
    {
        path: '/master/company',
        element: <Company />,
        layout: 'default',
    },
    {
        path: '/master/customer',
        element: <Customer />,
        layout: 'default',
    },
    {
        path: '/master/site',
        element: <Site />,
        layout: 'default',
    },
    {
        path: '/master/site/add',
        element: <SiteAdd />,
        layout: 'default',
    },
    {
        path: '/master/site/edit',
        element: <SiteAdd />,
        layout: 'default',
    },
    {
        path: '/master/pettyCash/head',
        element: <PettyCashHead />,
        layout: 'default',
    },
    {
        path: '/master/pettyCash/subhead',
        element: <PettyCashSubHead />,
        layout: 'default',
    },
    {
        path: '/master/pettyCash/items',
        element: <PettyCashItems />,
        layout: 'default',
    },
    {
        path: '/employees/list',
        element: <EmployeesList />,
        layout: 'default',
    },
    {
        path: '/employees/add',
        element: <EmployeesAdd />,
        layout: 'default',
    },
    {
        path: '/employees/edit',
        element: <EmployeesAdd />,
        layout: 'default',
    },
    {
        path: '/employees/designation/list',
        element: <DesignationList />,
        layout: 'default',
    },
    {
        path: '/employees/designation/add',
        element: <DesignationAdd />,
        layout: 'default',
    },
    {
        path: '/employees/designation/edit',
        element: <DesignationAdd />,
        layout: 'default',
    },
    {
        path: '/login',
        element: <Login />,
        layout: 'blank',
    },
    {
        path: '/password-reset',
        element: <RecoverPassword />,
        layout: 'blank',
    },

];

export { routes };
