import React, { PropsWithChildren } from 'react';
import App from '../../App';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://d9fd1aa55b35d1344ae73a83aa48f163@o4504320092995584.ingest.us.sentry.io/4508059907391488",
    integrations: [],
    tracesSampleRate: 1.0,
});

const BlankLayout = ({ children }: PropsWithChildren) => {
    return (
        <App>
            <div className="text-black dark:text-white-dark min-h-screen">{children} </div>
        </App>
    );
};

export default BlankLayout;
